/* eslint-disable react/jsx-no-useless-fragment */
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import useCompany from '@/utils/hooks/company/useCompany';
import type { ZustandState } from '../store/zustand_store';
import useZustandStore from '../store/zustand_store';
type GuestLayoutProps = {
  children: React.ReactNode;
};
const GuestLayout = ({
  children
}: GuestLayoutProps) => {
  const router = useRouter();
  const {
    isLoggedIn,
    activeReport
  } = useZustandStore((state: ZustandState) => state);
  const {
    isSuccess,
    activeCompany,
    userCompanies,
    setActiveCompany
  } = useCompany();
  useEffect(() => {
    if (isSuccess && userCompanies.length > 0 && activeCompany?.id !== userCompanies[0]?.id) {
      setActiveCompany(userCompanies[0]);
    }
  }, [isLoggedIn, router, activeReport, isSuccess, userCompanies, activeCompany?.id, activeCompany?.slug]);
  return <>{children}</>;
};
export default GuestLayout;