/* eslint-disable simple-import-sort/imports */

import LogIn from '@/components/authComponents/LogIn';
import GuestLayout from '@/miscellaneous/layouts/GuestLayout';

// Represents the Login component.
const Login = () => {
  return <GuestLayout data-sentry-element="GuestLayout" data-sentry-component="Login" data-sentry-source-file="login.tsx">
      <LogIn data-sentry-element="LogIn" data-sentry-source-file="login.tsx" />
    </GuestLayout>;
};
export default Login;