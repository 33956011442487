/* eslint-disable simple-import-sort/imports */
import dynamicRoute from '@/miscellaneous/constant';
import AppLoader from '@/miscellaneous/layouts/AppLoader/AppLoader';
import mixpanel from '@/mixpanelConfig';
import { identifyUserForMixpanel, MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import { variables } from '@/styles/variables/constant';
import { useIsLoggedIn } from '@/utils/hooks/useIsLoggedIn';
import { useAuth0 } from '@auth0/auth0-react';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import 'react-toastify/dist/ReactToastify.css';
import { useShallow } from 'zustand/react/shallow';

/**
 * Represents the Login component.
 * @function Login
 * @returns {JSX.Element} JSX element representing the login page.
 */
const Login = () => {
  const {
    loginWithRedirect,
    user,
    getAccessTokenSilently
  } = useAuth0();
  const {
    setIsLoggedIn
  } = useIsLoggedIn();
  const router = useRouter();
  const existingDistinctId = localStorage.getItem('distincId');
  const {
    hasTrackedLanding,
    setHasTrackedLanding
  } = useZustandStore(useShallow(state => ({
    hasTrackedLanding: state.hasTrackedLanding,
    setHasTrackedLanding: state.setHasTrackedLanding
  })));
  const getAccessToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    return token;
  }, []);
  useEffect(() => {
    const lucidUser = localStorage.getItem(variables.lucid_user) ? JSON.parse((localStorage.getItem(variables.lucid_user) as string)) : null;
    const queryParams = new URLSearchParams(router.asPath?.split('?')?.[1]);
    if (queryParams.has('error')) {
      const params = new URLSearchParams(queryParams);
      router.push(`${dynamicRoute.accessError}?${params?.toString()}`);
    } else if (!lucidUser) {
      getAccessToken().then(token => {
        if (token) {
          const userData = {
            username: user?.email,
            access_token: token,
            token_type: 'bearer'
          };

          // Send user data to mixpanel if user is logged in
          if (user) {
            // Send user data to hotjar
            hotjar.identify((user?.email as string), {
              email: user?.email
            });

            // Send user data to mixpanel
            const distinctId = existingDistinctId || user?.email;
            if (distinctId) {
              mixpanel.identify(distinctId);
              if (existingDistinctId && user?.email) {
                mixpanel.alias(user.email, existingDistinctId);
              }
            }
            identifyUserForMixpanel(user);

            // Only track landing event if not already tracked
            if (!hasTrackedLanding) {
              mixpanelTrackEventWrapper(MIXPANEL_EVENTS.USER_LANDED_ON_PLATFORM, {
                userEmail: user?.email,
                userName: user?.name,
                userId: user?.sub
              });
              setHasTrackedLanding(true);
            }
          }
          setIsLoggedIn(userData);
        } else {
          // send custom varaible to custom login page with loginWithRedirect
          loginWithRedirect();
        }
      }).catch(() => {
        loginWithRedirect();
      });
    }
  }, [user]);
  return <AppLoader data-sentry-element="AppLoader" data-sentry-component="Login" data-sentry-source-file="LogIn.tsx" />;
};
export default Login;